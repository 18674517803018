<template>
  <section
    class="cuckoo-contact"
  >
    <div class="text-center">
      <h1 class="mt-5 mb-5 bold">
        Choose your contract
      </h1>
    </div>

    <b-row class="">
      <b-col
        offset-sm-3
        sm="9"
        md="12"
        offset-lg="3"
        lg="9"
        class="mx-auto"
      >
        <b-row>
          <b-col
            xs="12"
            md="6"
            v-for="item in cuckoo" :key="item.id"
          >
            <b-card
              class="cuckoo-contact-card"
            >
              <div
                class="text-left mb-2"
              >
                <span
                  class="cuckoo-badge"
                >
                  {{ item.header }}
                </span>
              </div>
              <h2 class="mb-2">{{ item.title }}</h2>
              <b-card-text class="mb-4">
                {{ item.subtitle }}
              </b-card-text>


              <b-card-text>
                <h6>{{ item.footer }}</h6>
              </b-card-text>

              <b-button
                block
                class="mt-2 cuckoo-button"
                :disabled="item.disabled"
                :to="{ name: 'dashboard-cuckoo-info' }"
              >
                {{ item.messageButton }}
              </b-button>
            </b-card>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BFormCheckbox, BRow, BCol, BCard, BImg, BCardText, BListGroup, BListGroupItem, BButton, BBadge,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BFormCheckbox,
    BButton,
    BCardText,
    BListGroup,
    BListGroupItem,
    BCard,
    BBadge,
    BImg,
  },
  data() {
    return {
      cuckoo: {},
    }
  },
   created() {
    this.$http.get('/cuckoo/contact').then(res => { this.cuckoo = res.data })
  },

}
</script>

<style lang="scss">
  .cuckoo-contact{
    .bold{
      font-weight: 700;
    }

    .cuckoo-contact-card{
      border-radius: 20px;
      .cuckoo-button{
        border-radius: 100px;
        background-color: rgb(5, 128, 229) !important;
        color: rgb(255, 255, 255) !important;
        padding: 20px 25px;
        text-decoration: none;
        font-weight: 700;
        border: none;
      }

      .cuckoo-badge{
        background-color: rgb(255, 232, 2) !important;
        color: #000;
        border-radius: 36px;
        font-size: 13px;
        font-weight: 700;
        margin-bottom: 10px;
        padding: 4px 11px;
        text-transform: uppercase;
        width: fit-content;
      }
    }
  }

</style>